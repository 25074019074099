import { ActivatedRoute } from "@angular/router";

export class RoutingUtility {
  static getDeepestRoute(route: ActivatedRoute): ActivatedRoute {
    let deepestRoute = route;
    while (deepestRoute?.firstChild) {
      deepestRoute = deepestRoute.firstChild;
    }
    return deepestRoute;
  }

  static getDeepestRouteProp<T>(route: ActivatedRoute, key: string): T | null {
    const value = RoutingUtility.getDeepestRoute(route).snapshot.data[key];

    if (value !== null && typeof value !== "undefined") return value as T;
    return null;
  }
}
